import axios from "axios";

const axiosClient = axios.create({
    baseURL: 'https://healthhepta-versel-fsea.vercel.app',
    timeout: 80000,
    withCredentials: true,
})

axiosClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if(token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        if (!(config.data instanceof FormData)) {
            config.headers["Content-Type"] = "application/json";
        }
        // If it is FormData, axios will automatically set the correct Content-Type
        
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


//52.203.121.35
//https://api.healthhepta.com
// http://localhost:8081
// https://healthhepta-versel-fsea.vercel.app
// http://localhost:5000

export default axiosClient;