import React, { useState } from 'react'
import {
    Link
} from "react-router-dom";
import Modal from 'react-modal';
import SearchMedicinesStoreByLocation from '../ModalSearchMedicinesStoreByLocation';
import ModalBookAppointment from '../ModalBookAppointment';
import ModalSearchLabByLocation from '../ModalSearchLabByLocation';
import ModalSearchClinicByLoaction from '../ModalSearchClinicByLoaction';
import doctorImage from '../../img/docto image.jpg'
import medicinesImage from '../../img/medicineImageHeader2.png'
import labTestImage from '../../img/labImageHeader2.png'
import clinicImage from '../../img/ClinicImageHeader2.png'
import '../style.css'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const fontStyle={
    fontSize:'calc(1vw + 0.5rem)'
}
export default function B2BHeader2() {
    // const [modalIsOpen, setIsOpen] = React.useState(false);
    // const [state, setstate] = useState();
    // const [state, setstate] = useState();
    // const [state, setstate] = useState();
    const [searchByCategoryModalIsOpen, setSearchByCategoryModalIsOpen] = useState(false);

    function openModal(modalSetFunction) {
        modalSetFunction(true);
    }

    // function afterOpenModal() {
    //     document.body.style.backgroundColor = 'rgb(76 76 76 / 19%)'
    //     document.body.style.overflow = 'hidden';
    // }

    function closeModal(modalSetFunction) {
        document.body.style.overflow = 'unset';
        document.body.style.backgroundColor = '#fff'
        modalSetFunction(false);
    }
    return (
        // All div had class .Header2-search
        <div className='Header2' >
            <nav className="navbar navbar-expand-lg" style={{ backgroundColor: "#232f3e"}}>
                <div className="container-fluid Header-2 justify-content-evenly align-items-center">
                    <div>
                        <div className='d-none d-md-flex align-items-center text-white'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search my-3 " viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                            <div className="nav-link active text-white h2-t " style={fontStyle} aria-current="page" onClick={() => openModal(setSearchByCategoryModalIsOpen)}>
                                Shop By Category
                            </div>
                        </div>
                        <div className="d-flex d-md-none flex-column align-items-center">
                            <img 
                                src={doctorImage} 
                                alt="" 
                                style={{
                                    display: 'block',
                                    width: '10vw',
                                    borderRadius: '50%'
                                }}
                            />
                            <div className="nav-link active text-white h2-t " style={fontStyle} aria-current="page" onClick={() => openModal(setSearchByCategoryModalIsOpen)}>
                                Categories
                            </div>
                        </div>
                        <Modal
                            isOpen={searchByCategoryModalIsOpen}
                            onRequestClose={() => closeModal(setSearchByCategoryModalIsOpen)}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <ModalBookAppointment closeTheModal={() => closeModal(setSearchByCategoryModalIsOpen)} />

                        </Modal>
                    </div>
                    <div className="nav-item text-white">
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <img 
                                src={medicinesImage} 
                                alt="" 
                                className='d-flex d-md-none'
                                style={{
                                    display: 'block',
                                    width: '10vw',
                                    borderRadius: '50%'
                                }}
                            />
                            <div className="nav-link active text-white h2-t" style={fontStyle}>Quick Orders</div>
                        </div>
                        {/* <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >

                            <SearchMedicinesStoreByLocation closeTheModal={closeModal} />
                        </Modal> */}
                    </div>
                    <div className="nav-item text-white">
                        <div className="d-flex flex-column align-items-center jusitfy-content-center">
                            <img 
                                src={medicinesImage} 
                                alt=""
                                className='d-flex d-md-none'
                                style={{
                                    display: 'block',
                                    width: '10vw',
                                    borderRadius: '50%'
                                }} 
                            />
                            <div className="nav-link active text-white h2-t  " style={fontStyle}>Best Deals</div>
                        </div>
                        {/* <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >

                            <SearchMedicinesStoreByLocation closeTheModal={closeModal} />
                        </Modal> */}
                    </div>
                    <div className="nav-item text-white">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <img 
                                src={labTestImage} 
                                alt="img...." 
                                className='d-flex d-md-none'
                                style={{
                                    display: 'block',
                                    width: '10vw',
                                    borderRadius: '50%'
                                }}
                            />
                            <div className="nav-link active text-white h2-t " style={fontStyle}>My Orders</div>
                        </div>
                        {/* <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <ModalSearchLabByLocation closeTheModal={closeModal} />

                        </Modal> */}
                    </div>
                    <div className="nav-item text-white">
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <img 
                                src={clinicImage} 
                                alt="" 
                                className='d-flex d-md-none'
                                style={{
                                    display: 'block',
                                    width: '10vw',
                                    borderRadius: '50%'
                                }}
                            />
                            <div className="nav-link active text-white h2-t " style={fontStyle} aria-current="page">Contact us</div>
                        </div>
                        {/* <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <ModalSearchClinicByLoaction closeTheModal={closeModal}/>
                        </Modal> */}
                    </div>
                    
                </div>
            </nav>
        </div>
    )
}
