import React, { useState, useEffect, useContext, createContext } from "react";

const SubAdminAuthContext = createContext();

export function SubAdminAuthProvider({ children }) {
  const [jwtToken, setJwtToken] = useState('');

  useState(() => {
    const storedToken = localStorage.getItem('sub-admin-token');
    if(storedToken) {
      setJwtToken(storedToken);
    }
  }, []);

  const subAdminLogin = (token) => {
    if(token) {
      localStorage.setItem('token', token);
      setJwtToken(token);
    }
  }

  const subAdminLogout = () => {
    localStorage.removeItem('token');
    setJwtToken('');
  }

  return (
    <SubAdminAuthContext.Provider value={{ 
      jwtToken,
      subAdminLogin,
      subAdminLogout
     }}>
      {children}
    </SubAdminAuthContext.Provider>
  );
}

export function useSubAdminAuth() {
  return useContext(SubAdminAuthContext);
}